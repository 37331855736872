
@import "../../bower_components/normalize.css/normalize.css";
@import "../../bower_components/bootstrap/dist/css/bootstrap.css";

@import "partials/app";


@font-face {
  font-family: 'Akrobat-Black';
  font-style: normal;
  font-weight: normal;
  src: local('Akrobat-Black'), url('../fonts/Akrobat-Black.woff') format('woff'); }

@font-face {
  font-family: 'Akrobat-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Akrobat-Light'), url('../fonts/Akrobat-Light.woff') format('woff'); }

