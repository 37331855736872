body {
  font-family: 'Akrobat-Light'; }

* {
  -webkit-font-smoothing: subpixel-antialiased !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility:    hidden;
  -ms-backface-visibility:     hidden; }

button:active {
  outline: none; }
button:focus {
  outline: none; }
button::-moz-focus-inner {
  border: 0; }

input:focus {
  outline: none; }

a {
  text-decoration: none; }

p {
  font-family: 'Akrobat-Light';
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  color: #9b9b9b; }

img {
  max-width: 100%; }

section {
  padding: 60px 0; }

.header {
  background: url("../img/header-bg.jpg") no-repeat;
  background-position: 0 -50px;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  padding: 30px 0; }

.header-top {
  margin-bottom: 70px; }

.menu {
  margin-top: 15px;
  ul {
    float: right;
    li {
      list-style: none;
      float: left;
      margin: 0 30px;
      position: relative;
      &:last-child {
        margin-right: 0; }
      a {
        font-family: 'Akrobat-Light';
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        text-align: right;
        color: #ffffff;
        transition: all 1s;
        text-decoration: none;
        z-index: 99;
        cursor: pointer;
        position: relative; }
      &:hover:before {
        content: '';
        background: #DADED4;
        position: absolute;
        left: -40%;
        width: 180%;
        height: 100%;
        border-radius: 60px;
        transition: all 1s;
        z-index: 1; } } } }



.form-title {
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Akrobat-Light';
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  color: #00b6f0;
  text-transform: uppercase;
  span {
    font-family: 'Akrobat-Black';
    font-size: 48px;
    font-style: normal;
    font-stretch: normal;
    color: #02aaf0;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); } }

.header-form {
  margin-left: 20%;
  form {
    padding: 10px 40px;
    width: 347px;
    max-width: 100%;
    min-height: 430px;
    border-radius: 12px;
    -webkit-backdrop-filter: blur(11.4px);
    backdrop-filter: blur(11.4px);
    background-color: rgba(254, 254, 254, 0.81);
    input {
      display: block;
      width: 100%;
      max-width: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      border: 0;
      margin-bottom: 30px;
      padding: 10px 20px;
      font-family: 'Akrobat-Light';
      font-size: 20px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      color: #9b9b9b; }
    .send-btn {
      font-family: 'Akrobat-Black';
      border-radius: 60px;
      padding: 5px 20px;
      background-image: linear-gradient(to bottom, #82dbff, #4ecafe);
      box-shadow: 0 5px 8px 0 rgba(77, 192, 223, 0.6);
      width: 208px;
      font-size: 30px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      color: #ffffff;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
      transition: 1s;
      &:hover {
        box-shadow: 0 5px 8px 0 rgba(77, 192, 223, 0.6);
        transition: 1s; }
      &:active {
        background-image: linear-gradient(to bottom, #82dbff, #4ecafe);
        box-shadow: 0 1px 4px 0 rgba(77, 192, 223, 0.6); }
      &:focus {
        background-image: linear-gradient(to bottom, #82dbff, #4ecafe);
        box-shadow: 0 1px 4px 0 rgba(77, 192, 223, 0.6); } } } }


//mask

.mask {
  background: url("../img/couple-no-beach.jpg") no-repeat;
  background-size: cover;
  padding-bottom: 410px;
  background-position: -50px 0px;
  .title {
    margin-top: 0; } }

.title {
  font-family: 'Akrobat-Black';
  font-size: 48px;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  text-transform: uppercase;
  color: #02aaf0; }

.mask-slider {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    background: url("../img/quality.png") no-repeat;
    background-size: cover;
    width: 165px;
    height: 161px;
    top: 0; } }

.mask-about {
  h3 {
    margin-bottom: 30px; } }

.slick-arrow {
  display: none!important; }

.slick-dots {
  margin-top: 30px;
  margin-left: 50%;
  li {
    cursor: pointer;
    float: left;
    margin-right: 15px;
    list-style: none;
    background-color: #ffffff;
    width: 7px;
    height: 7px;
    padding: 0;
    border: 0;
    border-radius: 50%; }
  .slick-active {
    background-color: #4dcafe; }
  button {
    display: none; } }

.single-item {
  .slick-slide {
    img {
      margin-left: 34%;
      position: relative;
      width: 65%; } } }

.slick-slide {
  outline: 0;
  cursor: pointer; }

//advantages

.adv-title {
  text-align: center;
  .title {
    margin-top: 0; } }

.adv-list {
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      float: left;
      width: 190px;
      text-align: center;
      margin-right: 45px;
      &:last-child {
        margin-right: 0; }
      h3 {
        font-family: 'Akrobat-Light';
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        color: #000000;
        min-height: 70px; }
      img {
        height: 70px; } } } }

//bonus

.bonus {
  background: url("../img/bonus-bg.jpg") no-repeat;
  background-size: cover;
  background-position: 0px -25px; }

.bonus {
  .adv-list {
    margin-bottom: 130px; }
  ul {
    margin-top: 25px;
    li {
      &:hover img {
        cursor: pointer;
        position: relative;
        transition: all 1s;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -o-transform: scale(1.2); }
      &:last-child {
        margin-right: 0; }
      img {
        width: 160px;
        height: auto;
        transition: all 1s; } } } }


.left-bonus {
  position: relative;
  &:after {
    content: '+';
    position: absolute;
    right: -15%;
    top: 50%;
    font-size: 64px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    color: #ffffff; }
  h3 {
    font-family: 'Akrobat-Black';
    font-size: 64px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase; }
  img {
    position: relative;
    top: -20px; } }

.right-bonus {
  text-align: right;
  img {
    margin-top: 10%;
    width: 70%; } }

//byon

.by-on {
  background-color: #F2F8FC;
  text-align: center;
  padding: 75px 0; }

.by-on-amazon {
  font-family: 'Akrobat-Black';
  border-radius: 60px;
  background-image: linear-gradient(to top, #4dcafe, #84dbff);
  box-shadow: 0 5px 8px 0 rgba(21, 185, 243, 0.26);
  font-size: 24px;
  font-style: normal;
  font-stretch: normal;
  text-align: right;
  text-transform: uppercase;
  padding: 5px 40px;
  color: #ffffff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 0;
  transition: 1s;
  &:hover {
    box-shadow: 0 5px 8px 0 rgba(77, 192, 223, 0.6);
    transition: 1s; }
  &:active {
    background-image: linear-gradient(to bottom, #82dbff, #4ecafe);
    box-shadow: 0 1px 4px 0 rgba(77, 192, 223, 0.6); }
  &:focus {
    background-image: linear-gradient(to bottom, #82dbff, #4ecafe);
    box-shadow: 0 1px 4px 0 rgba(77, 192, 223, 0.6); } }

//catalog

.catalog-title {
  h3 {
    margin-top: 0; } }

.cat-prod {
  cursor: pointers;
  min-height: 500px!important;
  padding: 30px 15px;
  background: url("../img/rect.svg") no-repeat;
  background-size: cover;
  background-position: -250px;
  margin: 30px 17px;
  box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.06); }

.left-cat-pr {
  width: 35%;
  display: inline-block;
  vertical-align: bottom;
  bottom: 50%;
  img {
    width: 170px;
    max-width: 100%;
    margin: 0 auto; } }

.right-cat-pr {
  vertical-align: top;
  width: 60%;
  display: inline-block;
  padding: 0 5%;
  h3 {
    font-family: 'Akrobat-Black';
    font-size: 30px;
    font-style: normal;
    font-stretch: normal;
    color: #4dcafe;
    text-transform: uppercase; }
  .cat-text {
    margin: 30px 0;
    p {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      color: #000000; } }
  .sizes {
    margin-bottom: 30px;
    .size-bl {
      font-family: 'Akrobat-light';
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      color: #000000;
      display: inline-block;
      margin-right: 15px;
      border: 1px solid #4dcafe;
      padding: 2px 10px; }
    .size-no {
      border: solid 1px #dbdbdb;
      color: #cecece; } } }

.multiple-items {
  .slick-dots {
    margin-left: 44%;
    li {
      background-color: #e9e9e9;
      width: 10px;
      height: 10px; }
    .slick-active {
      background-color: #4dcafe; } } }

//footer

.footer {
  position: relative;
  padding: 30px 0; }

.footer-title {
  h3 {
    color: #000000;
    margin: 0 0 30px 0; } }

.left-footer {
  form {
    input {
      width: 100%;
      margin: 15px 0;
      padding: 5px 20px;
      font-family: 'Akrobat-light';
      font-size: 20px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      color: #000000; }
    textarea {
      width: 100%;
      padding: 5px 20px;
      font-family: 'Akrobat-light';
      font-size: 20px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      color: #000000;
      min-height: 175px; } } }

.ftr-form-title {
  font-family: 'Akrobat-light';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #000000; }

.ftr-bl {
  margin-bottom: 15px;
  font-family: 'Akrobat-light';
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #000000;
  a {
    font-family: 'Akrobat-light';
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    color: #000000; } }

.ftr-btn {
  width: 220px!important;
  padding: 10px 40px!important;
  border-radius: 40px;
  background-color: #000000;
  font-family: 'Akrobat-light';
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  color: #ffffff!important;
  text-transform: uppercase;
  border: 0;
  transition: 1s;
  &:hover {
    transition: 1s;
    color: #fff!important; }
  &:active {
    background-color: #fff!important;
    color: #000000!important; }
  &:focus {
    background-color: #fff!important;
    color: #000000!important; } }


.ab-right {
  margin: 15% 0 0 15%;
  min-height: 345px; }

//copyright

.copyright {
  padding: 10px 0;
  background-color: #4dcafe;
  .col-cop {
    position: relative;
    top: 15px;
    color: #ffffff; }
  .empo-logo {
    text-align: center;
    img {
      height: 55px; } }
  .logo {
    text-align: right;
    img {
      height: 30px; } } }

.shown {
  padding: 27px 60px;
  width: 50%;
  min-height: 455px;
  color: #fff;
  background: #fff;
  position: absolute;
  right: 0;
  display: none;
  .title-about {
    h3 {
      font-family: 'Akrobat-Black';
      font-size: 48px;
      font-style: normal;
      font-stretch: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase;
      margin-top: 0; } } }

.show-text {
  margin: 25px 0;
  p {
    font-family: 'Akrobat-light';
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    color: #ffffff; } }

.shown.intro {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(93, 207, 254, 0.95);
  display: block !important; }

.more-btn {
  text-align: center;
  margin-top: 45px;
  .more {
    background-color: #fff;
    color:  #4dcafe!important;
    transition: 1s;
    &:hover {
      color:  #4dcafe!important;
      transition: 1s; }
    &:active {
      background-color: #4dcafe!important;
      color: #fff!important; }
    &:focus {
      background-color: #4dcafe!important;
      color: #fff!important; } } }

